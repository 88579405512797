<template>
  <inputControl
    v-bind="propsNoValue"
    class="wet-input-control_textarea"
    @update:focused="(v) => focused = v"
  >
    <textarea
      :type="type"
      :placeholder="labeled && placeholder"
      :id="_id"
      v-model="inputValue"
      :class="{
        'input-right': right,
        [inputClass]: !!inputClass,
      }"
      :disabled="disabled"
      :inputmode="inputmode"
      rows="5"
      @focus="$emit('update:focused', true)"
      @blur="$emit('update:focused', false)"
    ></textarea>
  </inputControl>
</template>

<script>
import inputControl from '@/components/ui/inputControlUi.vue';
import inputMixin from '@/mixins/ui/inputMixin';

export default {
  mixins: [inputMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    inputControl,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    propsNoValue() {
      const props = {
        ...this.$props,
        focused: this.focused,
      };
      delete props.value;
      return props;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        const numVal = val === '' ? undefined : val;
        this.$emit('input', numVal);
      },
    },
  },
};
</script>

<style lang="scss">
.wet-input-control_textarea {
  & .input-bg-left {
    border-radius: 1.45rem 0 0 1.45rem;
  }

  & .input-bg-right {
    border-radius: 0 1.45rem 1.45rem 0;
  }
}
</style>
